<div *ngIf="matches" class="interpro-matches">
  <div class="interpro-matches-title">
Protein families and domains
  </div>

  <table class="interpro-matches-table" *ngIf="displayMatches.length > 0">
    <thead>
      <tr>
        <th>Match ID</th>
        <th>DB name</th>
        <th>Match name</th>
        <th>InterPro ID</th>
        <th>InterPro name</th>
        <th>Start</th>
        <th>End</th>
        <th>Count</th>
      </tr>
    </thead>
    <tr [class.highlighted]="match.id == highlightedId"
        (mouseenter)="highlightMatch(match.id)"
        *ngFor="let match of displayMatches">
      <td>
        <span *ngIf="!match.dbEntryUrl">{{match.id}}</span>
        <a *ngIf="match.dbEntryUrl" title="View {{match.id}} at {{match.dbDisplayName}}"
           href="{{match.dbEntryUrl}}">{{match.id}}</a>
      </td>
      <td>
        <span title="{{match.dbDescription}}">{{match.dbDisplayName}}</span>
      </td>
      <td>
        {{match.name}}
      </td>
      <td>
        <span *ngIf="!match.interProEntryUrl">{{match.interpro_id}}</span>
        <a *ngIf="match.interProEntryUrl" title="View {{match.interpro_id}} at InterPro"
           href="{{match.interProEntryUrl}}">{{match.interpro_id}}</a>
      </td>
      <td>
        {{match.interpro_name}}
      </td>
      <td>
        <div *ngFor="let loc of match.locations">
          {{loc.start}}
        </div>
      </td>
      <td>
        <div *ngFor="let loc of match.locations">
          {{loc.end}}
        </div>
      </td>
      <td>
        <a *ngIf="match.geneCount" title="{{match.countLinkTitle}}"
           routerLink="{{match.countLinkUrl}}">{{match.geneCount}}</a>
      </td>
    </tr>
  </table>

  Domain organization at
  <a *ngIf="uniprotIdentifier"
     href="https://www.ebi.ac.uk/interpro/protein/{{uniprotIdentifier}}">
    InterPro ...
  </a>
</div>
