<div>
  <div class="loading" *ngIf="resultTable.length == 0">
    Loading ...
  </div>
  <div *ngIf="resultTable.length != 0">
    <div class="description">
{{slimConfig.slim_results_description}}
    </div>
    <div class="subset-table">
      <h4>Terms and genes</h4>
      <table class="subset-table">
        <thead>
          <th>Name</th>
          <th>Term</th>
          <th>Genes</th>
        </thead>
        <tbody>
          <tr *ngFor="let row of resultTable">
            <td class="term-name">{{row.termName}}</td>
            <td class="term-id">
              <a routerLink="/term/{{row.termId}}">{{row.termId}}</a>
            </td>
            <td class="count">
              <a *ngIf="row.geneUniquenames.length != 0" (click)="gotoGenesOfTerm(row.termName, row.termId)">{{row.geneUniquenames.length}}</a>
              <span *ngIf="row.geneUniquenames.length == 0">0</span>
            </td>
          </tr>
        </tbody>
      </table>
  </div>
  <div *ngIf="countsReady" class="summary">
    <h4>Summary</h4>
    <div class="summary-content">
    <ul>
    <li>
      Total slimmed gene products (protein and ncRNA): {{slimmedGenes.size}}
    </li>
    <li>
      Protein coding genes not covered by the slim:
      <a *ngIf="unslimmedGenes.size != 0" (click)="gotoUnslimmedGenes()">{{unslimmedGenes.size}}</a>
      <span *ngIf="unslimmedGenes.size == 0">0</span>
    </li>
    </ul>
    </div>
    <div class="summary-content">
      <ul>
        <li>
          See <a routerLink="{{slimConfig.full_slim_path}}">full {{slimConfig.slim_display_name}} set</a> for more information
        </li>
      </ul>
    </div>
  </div>
 </div>
</div>
