<span class="extension-display">
  <span class="extension-part" *ngFor="let extPart of displayExtension">
    <span [class.extension-rel-name]="highlightRelations">{{extPart.relTypeName}}</span>
    <span *ngFor="let range of extPart.extRange; let i = index">

      <span *ngIf="range.term">
        <app-term-link [term]="range.term">{{range.term.name}}</app-term-link>
      </span>

      <span *ngIf="range.summaryTerms">
        <span class="summary-term-and-comma"><span *ngFor="let term of range.summaryTerms; let lastTerm = last"> <app-term-link [term]="term">{{term.name}}</app-term-link><span *ngIf="!lastTerm">,</span></span></span>
      </span>

      <span *ngIf="range.gene">
        <app-gene-link class="app-link" [long]="false" [gene]="range.gene"></app-gene-link>
      </span>

      <span *ngIf="range.transcript">
        <span *ngIf="range.transcript.gene">
          {{range.transcript.uniquename}}
          (<app-gene-link  class="app-link" [long]="false" [gene]="range.transcript.gene"></app-gene-link>)
        </span>
        <a *ngIf="!range.transcript.gene" routerLink="/gene/{{range.transcript.gene_uniquename}}">{{range.transcript.uniquename}}</a>
      </span>

      <span *ngIf="range.promoter">
        <span *ngIf="range.promoter.gene">
          promoter of <app-gene-link class="app-link" [long]="false" [gene]="range.promoter.gene"></app-gene-link>
        </span>
        <span *ngIf="range.promoter.displayName">
          {{range.promoter.displayName}}
        </span>
      </span>

      <span *ngIf="range.summary_residues">
        <span *ngFor="let residue of range.summary_residues; let lastSumm = last">
          <span [popover]="residueTitle(residue)" triggers="mouseenter:mouseleave"
                container="body">{{residue}}</span><span *ngIf="!lastSumm">, </span>
        </span>
      </span>

      <span *ngIf="range.summaryGenes">
        <span *ngFor="let summ of range.summaryGenes; let lastSumm = last">
          <span class="summary-gene-and-comma"><span *ngFor="let gene of summ; let lastGene = last"><app-gene-link class="app-link" [long]="false" [gene]="gene"></app-gene-link><span *ngIf="!lastGene"> and </span></span><span *ngIf="!lastSumm">,</span></span>
        </span>
      </span>

      <span *ngIf="range.summaryTranscripts">
        <span *ngFor="let summ of range.summaryTranscripts; let lastSumm = last">
          <span class="summary-transcript-and-comma"><span *ngFor="let tr of summ; let lastTr = last"><span *ngIf="tr.gene">{{tr.uniquename}} (<app-gene-link class="app-link" [long]="false" [gene]="tr.gene"></app-gene-link>)</span><a *ngIf="!tr.gene" routerLink="/gene/{{tr.gene_uniquename}}">{{tr.uniquename}}</a><span *ngIf="!lastTr"> and </span></span><span *ngIf="!lastSumm">,</span></span>
        </span>
      </span>

      <span *ngIf="range.misc">
        <a *ngIf="range.misc.link" [attr.href]="range.misc.link">{{range.misc.value}}</a><span *ngIf="!range.misc.link">{{range.misc.value}}</span>
      </span>

      <span *ngIf="range.gene_product">
        <a title="{{range.gene_product.id}}"
           [attr.href]="range.gene_product.link">{{range.gene_product.displayName}}</a>
      </span>

      <span *ngIf="range.gene_and_gene_product">
        <app-gene-link *ngIf="range.gene_and_gene_product.gene"
                       class="app-link" [long]="false" [gene]="range.gene_and_gene_product.gene"></app-gene-link>
        (<a title="{{range.gene_and_gene_product.product.name || range.gene_and_gene_product.product.id}}"
           [attr.href]="range.gene_and_gene_product.product.link">{{range.gene_and_gene_product.product.displayName}}</a>)
      </span>

      <span *ngIf="range.domain">
        <a title="{{range.domain.id}}"
           [attr.href]="range.domain.link">{{range.domain.id}}</a>
      </span>
    </span>
  </span>
</span>
