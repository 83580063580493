<app-term-name-complete [cvName]="termNodeConfig.ontologyName!"
                        [placeholder]="placeholder"
                        (termMatched)="termMatched($event)"></app-term-name-complete>

<div class="term-definition" *ngIf="selectedTerm && selectedTerm.definition">{{selectedTerm!.definition}}</div>

<div *ngIf="isPhenotypeNode">
  <div class="phenotype-filter phenotype-filter-inline-block">
    <div>Include genes from these genotypes:</div>
    <div class="ontology-options single-or-multi-locus">
    <div>
    <input id="single-locus" type="checkbox" [(ngModel)]="singleLocus"
           title="Include genes from single locus genotypes"
           (ngModelChange)="singleMultiChange('single')"/>
    <label for="single-locus">Single locus</label>
    </div>
    <div>
    <input id="multi-locus" type="checkbox" [(ngModel)]="multiLocus"
           title="Include genes from multi-locus genotypes"
           (ngModelChange)="singleMultiChange('multi')"/>
    <label for="multi-locus">Multi-locus</label>
    </div>
    </div>
    <div class="ontology-options">
      <div>
      <input id="haploid" type="checkbox" [(ngModel)]="haploid"
             title="Include genes from haploid genotypes"
             (ngModelChange)="ploidinessChange('haploid')"/>
      <label for="haploid">Haploid</label>
      </div>
      <div>
      <input id="diploid" type="checkbox" [(ngModel)]="diploid"
             title="Include genes from diploid genotypes"
             (ngModelChange)="ploidinessChange('diploid')"/>
      <label for="diploid">Diploid</label>
      </div>
      </div>
  </div>

  <div *ngIf="!multiLocus && !diploid" class="phenotype-filter expression phenotype-filter-inline-block">
    <div>Expression level:</div>
    <div class="ontology-options">
    <div>
    <input id="expression-any" type="radio" [(ngModel)]="expression" name="expression" value="any"/>
    <label for="expression-any">Any</label>
    </div>
    <div>
    <input id="expression-null" type="radio" [(ngModel)]="expression" name="expression" value="null"/>
    <label for="expression-null">Null (deletion)</label>
    </div>
    <div>
    <input id="expression-wt-over" type="radio" [(ngModel)]="expression" name="expression" value="wt-overexpressed"/>
    <label for="expression-wt-over">Overexpressed wild type</label>
    </div>
    </div>
  </div>

  <div *ngIf="phenotypeConditionNamespace"
       class="phenotype-condition-selector phenotype-filter">

    <input id="show-cond-select" type="checkbox" [(ngModel)]="showConditionSelector"
           (change)="conditionSelectorChanged()"
           title="Click to constrain results to genes that have the phenotype in a particular experimental condition"/>
    <label for="show-cond-select">Constrain condition<span *ngIf="showConditionSelector">:</span></label>
    <app-term-name-complete *ngIf="showConditionSelector"
                            class="phenotype-condition-complete"
                            [cvName]="phenotypeConditionNamespace"
                            [placeholder]="'Start typing a phenotype condition, eg. low temperature'"
                            (termMatched)="phenotypeConditionMatched($event)"></app-term-name-complete>
   <a class="constrain-condition-help" routerLink="/documentation/advanced-search"
      [fragment]="'constraining-by-conditions'">
      <img src="/assets/info_icon.svg">
    </a>
  </div>

  <div *ngIf="phenotypeConditionNamespace"
       class="phenotype-condition-selector phenotype-filter">

    <input id="show-ex-cond-select" type="checkbox" [(ngModel)]="showExcludeConditionSelector"
           (change)="excludeConditionSelectorChanged()"
           title="Click to exclude genes that have a phenotype in a particular experimental condition"/>
    <label for="show-ex-cond-select">Exclude condition<span *ngIf="showExcludeConditionSelector">:</span></label>
    <app-term-name-complete *ngIf="showExcludeConditionSelector"
                            class="phenotype-condition-complete"
                            [cvName]="phenotypeConditionNamespace"
                            [placeholder]="'Start typing a phenotype condition, eg. low temperature'"
                            (termMatched)="phenotypeExcludeConditionMatched($event)"></app-term-name-complete>
  </div>
</div>

<div class="node-bottom-description" *ngIf="termNodeConfig.extraDescription">
  <span [innerHtml]="termNodeConfig.extraDescription"></span>
</div>

<div class="buttons">
  <button [disabled]="!isValid()" [attr.title]="submitTitle()"
          class="btn btn-primary" (click)="submit()">Submit</button>
</div>
