<div *ngIf="geneDetails.has_protein_features">

  <div class="header">
    <div>
      <span class="help-icon">
      <app-help-link [route]="'/documentation/gene-page-protein-features-widget'"
        [title]="'Click to view documentation for the protein feature widget'">
      </app-help-link>
      </span>

      <a routerLink="/gene_protein_features/{{geneDetails.uniquename}}">View all protein features ...</a>
    </div>
    <div>
    </div>
  </div>

  <iframe [src]="getIFrameURL()" class="embedded-protein-viewer" #proteinfeaturesiframe>
  </iframe>
</div>

<div *ngIf="!geneDetails.has_protein_features && geneDisplayName" class="no-features">
No features available for {{geneDisplayName}}
</div>
