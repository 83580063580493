<div class="front-panel {{conf.panel_type}}">
  <div class="head-img">
    <div *ngIf="headImage">
      <a *ngIf="headImageLink" href="{{headImageLink}}">
        <img loading="lazy" width="300" height="185" src="{{headImage}}" />
      </a>
      <img *ngIf="!headImageLink" loading="lazy" width="300" height="185" src="{{headImage}}" />
    </div>
  <video *ngIf="headVideo" width="300" autoplay>
    <source src="{{headVideo}}" type="video/mp4">
  </video>
  </div>

  <div class="panel-contents">
    <span *ngIf="showDateAdded" class="date-added">{{conf.date_added}}</span>
    <div class="title">
      <span *ngIf="panelDescription">{{panelDescription}}: </span>
      <span *ngIf="!conf.title_link">{{conf.title}}</span>
      <span><a *ngIf="conf.title_link" href="{{conf.title_link}}">{{conf.title}}</a></span>
    </div>
    <app-front-panel-content [panelId]="conf.internalId"></app-front-panel-content>
    <a *ngIf="conf.internalLink" routerLink="{{conf.internalLink}}">
      {{conf.link_label}}
    </a>
    <a *ngIf="conf.externalLink" href="{{conf.externalLink}}">
      {{conf.link_label}}
    </a>
  </div>
</div>
