<div *ngIf="geneDetails">

<!--  <a routerLink="/gene/{{geneDetails.uniquename}}">&lt;- Back to gene page</a> -->

  <div class="header details-page-name-and-id">
    <span>
    <span class="details-page-name-and-id-prefix">Protein features: </span>

    <a routerLink="/gene/{{geneDetails.uniquename}}">
      <span *ngIf="geneDetails.name">
        <span class="name-id"><span class="name-id-highlight">{{geneDetails.name}}</span> / {{geneDetails.uniquename}}</span>
      </span>
      <span class="gene-name" *ngIf="!geneDetails.name">
        <span class="name-id">{{geneDetails.uniquename}}</span>
      </span>
    </a>
    </span>

    <span class="help-link">
      <app-help-link [route]="'/documentation/gene-page-protein-features-widget'"
                     [title]="'Click to view documentation for the protein feature viewer'">
        Documentation ...
      </app-help-link>
    </span>

  </div>

  <div *ngIf="getIFrameURL()">
    <iframe [src]="getIFrameURL()" class="full-protein-viewer" #proteinfeaturesiframe>
    </iframe>
  </div>
</div>
